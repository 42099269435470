import React, {useState, useEffect} from 'react';

import { AmplifyAuthenticator, 
		//AmplifySignOut, 
		AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import "./style.css"
	
const GatedContent = (props) => {
	const [authState, setAuthState] = useState();
	const [user, setUser] = useState();

	useEffect(() => {
		return onAuthUIStateChange((nextAuthState, authData) => {
			setAuthState(nextAuthState);
			setUser(authData)
		});
	}, []);


	return authState === AuthState.SignedIn && user ? (
			<>
				{props.children}
			</>
		) : (
			<AmplifyAuthenticator usernameAlias="email">
				<AmplifySignIn
					headerText="Please sign-in"
					slot="sign-in" usernameAlias="email"
					hideSignUp
				/>			
				<AmplifySignUp
					slot="sign-up"
					usernameAlias="email"
					formFields={[
						{
							type: "email",
							label: "Email Address",
							placeholder: "email",
							required: true,
						},
						{
							type: "password",
							label: "Password",
							placeholder: "Password (At least 6 characters)",
							required: true,
						}
					]} 
				/>
			</AmplifyAuthenticator>
		);
}


export default GatedContent
